import './App.css';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const client = axios.create({
    baseURL: "https://gitez.cc/api/crossshare/v1/api/v1"
})

function App() {
    const [pull, setPull] = useState(null)
    const [push, setPush] = useState(null)

    // const [res, setRes] = useState({ key: "", code: 0, msg: "", ttl: 0, error: "", pull: "", end: "" })
    const [input, setInput] = useState("")

    const handleClear = () => {
        setInput("")
        setPull(null)
        setPush(null)
        // setRes({})
    }
    const handlePush = (e) => {
        e.preventDefault();

        setPull(null)
        // /api/v1/push
        // headers: 
        //     Filename
        // body:  bytes
        async function push() {
            const response = await client.post("/push", input)
            console.log("push response:", response)
            if (response.status === 200) {
                const data = response.data
                const current = new Date()
                current.setSeconds(current.getSeconds() + data.ttl)
                setPush({ key: data.key, ttl: data.ttl, end: current.toLocaleString() })
            } else {
                setPush({ error: response.status })
            }
        }
        push()
    }

    const handlePull = (e) => {
        e.preventDefault()

        setPush(null)
        async function pull() {
            const response = await client.get(`/pull/${input}`)
            console.log("pull response:", response)
            if (response.status === 200) {
                const data = response.data
                if (!data) {
                    // not found
                    toast("无效拉取码或已过期")
                    setPull(null)
                } else {
                    setPull({ data })
                }
            } else {
                setPull({ error: response.status })
            }

        }
        pull()
    }

    const handleChange = (e) => {
        setInput(e.target.value)
    }

    return (
        <div className="App">
            <ToastContainer
                autoClose={500}
                closeOnClick
            />

            <form>
                <div className='input'>
                    <textarea id="input" name="input" onChange={handleChange} value={input} placeholder='输入文本或拉取码'></textarea>
                </div>
                <div className='buttons'>
                    <button className="clearBtn" disabled={input.length === 0} onClick={handleClear}>清空</button>
                    <button className="pushBtn" disabled={input.length === 0} onClick={handlePush}>推送</button>
                    <button className="pullBtn" disabled={input.length === 0} onClick={handlePull}>拉取</button>
                </div>
                <div>
                    {push?.key && <div className="key">拉取码: {push.key}</div>}
                    {push?.key && <div className="key">TTL: {push.ttl}</div>}
                    {push?.key && <div className="key">过期时间: {push.end}</div>}

                    {push?.error && <div>{push.error}</div>}
                    {pull?.error && <div>{pull.error}</div>}

                    {pull?.data && <div className="pull">{pull.data}</div>}

                    {pull?.data &&
                        <CopyToClipboard
                            className="copyContainer"
                            text={pull.data}
                            onCopy={
                                () => {
                                    toast("copied")
                                }
                            }>

                            <span >Copy</span>
                        </CopyToClipboard>
                    }

                </div>
            </form>

        </div>
    );
}

export default App;
